<template>
  <div class='standard-transition rounded-lg flex flex-col justify-between relative program-session-card border-2 border-gray-100 bg-white pt-4'
    :class='cardClasses'
    :style='colorStyle'
    @click='openSessionModal'
    @mouseover='isHovering=true'
    @mouseleave='isHovering=false'>
    <div 
      class='session-title font-bold mt-2 whitespace-pre-line px-4'
      :class='titleTextSize'>
      <span v-if='showWatchedIndicator'
        class='float-right p-2 rounded-tr-lg bg-gray-50 text-sm font-medium opacity-90'
        :class='watchStatusBasedCss'
        style='margin-top: -1.5rem; margin-right: -1rem;'>
        {{ watchedStatusTagTitle(session.id) }}
      </span>
      {{session.title}}
    </div>
    <div>
      <div v-if='singleSpeaker'
        class='flex flex-row justify-start items-center gap-x-2 px-4'>
        <img 
          :src='session.listOfSpeakers[0].speakers[0].imageUrl'
          class='single rounded-full object-cover'>
        <div class='h-20 flex flex-col justify-center'>
          <div>
            {{session.listOfSpeakers[0].speakers[0].name}}
          </div>
          <div class='whitespace-pre-line'>
            {{session.listOfSpeakers[0].speakers[0].affiliation}}
          </div>
        </div>
      </div>
      <div v-else
        class='px-4'>
        <div 
          v-for='(topic, index) in session.listOfSpeakers'
          :key='`session-card-speaker-image-${topic.speaker.id}`'
          class='multiple-speaker-container absolute flex flex-col justify-start items-center'
          style='bottom: 1rem;'
          :style='positionProfileImage(index)'
          >
          {{topic}}
          <img 
            :src='session.listOfSpeakers[index].speakers[index].imageUrl'
            class='rounded-full object-cover multiple' 
            />
          <h2 class='speaker-name opacity-20 text-sm text-gray-800'>{{topic.speakers[0].name}}</h2>
        </div>
      </div>
      <div class='standard-transition bottom-row'>
        <div>
          {{ session.configurations.videoLengthString }}
        </div>
        <div v-if='eventProgramsPageSessionCardText'
          class='font-semibold flex flex-row justify-start items-center'>
          {{eventProgramsPageSessionCardText}}
          <arrow-narrow-right-icon />      
        </div>
      </div>
    </div>
    <div v-if='showNotYetOverlay'
      class='overlay-bg w-full h-full absolute top-0 left-0'
      :class='overlayDisplayCss'>
      <div class='w-full h-full bg-gray-900 opacity-60 absolute top-0 left-0 rounded-lg z-10'></div>
      <h1 class='w-full h-full text-center flex flex-col justify-center items-center absolute top-0 left-0 font-bold text-white text-4xl z-20'>
        곧 만나요<br>
        <span class='font-normal text-lg inline-block mt-4'>{{sessionExpectedStartDateString}}</span>
      </h1>
    </div>
  </div>
</template>

<script>
import DateTimeStringHelpers from '@/utils/date-time-string-helpers'
import { mapGetters, mapActions } from 'vuex'
import { ArrowNarrowRightIcon } from 'vue-tabler-icons'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

export default {
  name: 'SessionCard',
  components: {
    ArrowNarrowRightIcon,
  },
  data () {
    return {
      isHovering: false,
    }
  },
  props: [
    'session',
    'color',
    'cardTitleSize',
    'showOverlay',
  ],
  computed: {
    ...mapGetters('watchedVideos', [
      'watchedStatusTagTitle',
    ]),
    ...mapGetters('events', [
      'eventProgramsPageSessionCardText',
    ]),
    cardClasses () {
      return `${this.hoverClass}`
    },
    hoverClass () {
      return (this.isHovering) ? 'isHovering' : ''
    },
    highlightColor () {
      return `color: ${this.color}`
    },
    singleSpeaker () {
      return this.session.listOfSpeakers.length === 1
    },
    titleTextSize () {
      return (this.cardTitleSize) ? this.cardTitleSize : 'text-xl leading-7'
    },
    colorStyle () {
      return {
        '--cardColor': this.color
      }
    },
    showNotYetOverlay () {
      return this.showOverlay &&
             dayjs().isBefore(dayjs(this.session.scheduledStartTime, 'hour'))
    },
    opacityOverlayCss () {
      return (this.showNotYetOverlay) ? 'opacity-50' : ''
    },
    overlayDisplayCss () {
      return this.isHovering ? 'opacity-0' : 'opacity-100'
    },
    sameVideoScheduledStartDate () {
      return dayjs().tz('Asia/Seoul').isSame(dayjs(this.session.scheduledStartTime).tz('Asia/Seoul'), 'date')
    },
    sessionExpectedStartDateString () {
      let dateString = dayjs(this.session.scheduledStartTime).tz('Asia/Seoul').format('MM월 DD일')
      let dayOfWeek  = DateTimeStringHelpers.koreanDayOfWeek(this.session.scheduledStartTime)
      return `${dateString} (${dayOfWeek}) 12:00`
    },
    watchStatusBasedCss () {
      return this.watchedStatusTagTitle(this.session.id) === '시청완료' ? 'bg-green-50 text-green-800' : 'bg-gray-50 text-gray-800'
    },
    showWatchedIndicator () {
      return this.sameVideoScheduledStartDate && this.watchedStatusTagTitle(this.session.id)
    },
  },
  methods: {
    ...mapActions('sessions', [
      'showShowSessionModal',
    ]),
    openSessionModal () {
      this.$emit('show-session-details')
    },
    positionProfileImage (index) {
      let hoverOffset = (this.isHovering) ? 2.5 : 2.5
      let leftSpace = 0 + (index * 1.5 * hoverOffset)
      return `margin-left: ${leftSpace}rem`
    },
  }
}
</script>
<style lang='scss' scoped>
  img, 
  .speaker-name,
  .multiple-speaker-container,
  .overlay-bg {
    transition: all 0.2s ease-in-out;
  }

  img.single {
    @apply h-24 w-24;
  } 

  .program-session-card:hover {
    border-color: var(--cardColor);
  }

  .session-liked,
  .program-session-card:hover {
    @apply cursor-pointer;

    img {
      @apply opacity-100;
    }

    img.single {
      @apply h-28 w-28;
    }
  }

  img.multiple {
    @apply h-12 w-12;
  } 

  .speaker-name {
    @apply opacity-50;
  }

  .isHovering .speaker-name {
    @apply opacity-100;
  }

  .bottom-row {
    // @apply mt-6 flex flex-row justify-between items-center py-3 pl-4 pr-2 rounded-b-lg text-sm opacity-0;
    @apply mt-6 flex flex-row justify-between items-center py-3 pl-4 pr-2 rounded-b-lg text-sm opacity-70;
  }

  .program-session-card:hover {
    @apply cursor-pointer shadow-lg rounded-xl;
  }

  .program-session-card:hover .bottom-row {
    // @apply mt-6 rounded-b-xl opacity-0;
    @apply mt-6 rounded-b-xl opacity-100; // hide for now
  }
</style>

